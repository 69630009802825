// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { CSVLink } from 'react-csv';
import { useState } from "react";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import Link from '@mui/material/Link';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

// Audit Vault Components
import DisplayAndChooseTimezone from './DisplayAndChooseTimezone';

// Audit Vault Utilities
import { getRecordedTime, getTimeZoneUTCOffsetFormatFromBrowser, getUTCOffsetString } from '../../utilities/common-date-utils';
import AUDIT_LOG_RECORD_TYPE from '../../constants/constants-auditlogrecordtypes';
import DisplayFullAuditLogDetail from './DisplayFullAuditLogDetail';

/*
Reusable component that displays the exportable audit source data.
Doesn't load any data - just takes the data and displays it.

Updated Aug 26, 2024 - the data provided in reportResults no longer contains the full results, so if a row is clicked we have to load the specific data to be displayed.
This will make the load time much faster for the calling report overall.
This function now has to take in two additional properties when being called, the tenantId and the reportType.

Added Nov 24, 2023 - defaults to display dates in the data table as the browsers' default time zone.  
Allows the User to select a different IANA time zone to display to override the default browser's IANA time zone.

Parameters:
- columns array (columns to display and style/sizing)
- reportResults array (contains the results)
- csvFilename (exportable csv file name to use)

Future to do: Include option to export all items (use different icon - full dump -- instead of just exporting the items from the Columns array, export everything in the reportResults Array).
    ex.) Export Current, Export All 
*/
export default function DisplayExportableDataSource(props) {

    // Component Constants    
    const { columns, reportResults, csvFilename, tenantId, reportType, user, userAzureTenantId } = props;        
    const navigate = useNavigate();    
    
    // TimeZone display related.  Loading and translating the current timezone must be "light weight" in the browser for this page to keep it simple and less intensive on browser rendering.  As such we don't do any complex IANA or Windows timezone conversions on this page (> 400 timezones to translate).  
    const timeZoneBrowserFormatIana = Intl.DateTimeFormat().resolvedOptions().timeZone;  // Browser IANA timezone format.
    const myTime = new Date();  // Get the current time.
    const [timeZoneUTCOffsetFormat, setTimeZoneUTCOffsetFormat] = React.useState(getTimeZoneUTCOffsetFormatFromBrowser(myTime));  // Get the UTC Offset.    
    const [selectedTimeZone, setSelectedTimeZone] = React.useState(timeZoneBrowserFormatIana);  // Set default to what the browser reports.
    
    // Data table - settings.
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.
    const [expandedRow, setExpandedRow] = useState(null);  // For Accordion control. 

    // Component Functions.


    // Function for when the TimeZone is updated.
    const handleTimeZoneChange = (event) => {
        // Update the state for selectedTimeZone.  This will update the component + re-render automatically in React.
        setSelectedTimeZone(event.target.value);  
        // Update the display UTC format in the text as well.  This will update the component automatically in React.
        setTimeZoneUTCOffsetFormat(getUTCOffsetString(event.target.value));        
    };
    
    // For the optional table to display data source.
    // Function for Sorting column headers.    
    // PW: Updated July 1, 2024: If a row column value is null use empty string to sort instead, before it would not sort null items.
    function getComparator(sortConfig) {
        return (a, b) => {
            if (!sortConfig) {
                return 0;
            }            
            // Convert null values to empty strings for comparison
            const valueA = a[sortConfig.key] != null ? a[sortConfig.key] : '';
            const valueB = b[sortConfig.key] != null ? b[sortConfig.key] : '';            
            if (valueA < valueB) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        };
    }

    // Function for Sorting column headers.  
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    // Column header sort function.
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    // Change pages in the optional table.
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // Change rows per page in the optional table.
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Accordion row clicked.  Toggles the row open or closed.
    const handleRowClick = (id) => {        
        if (expandedRow === id) {
            setExpandedRow(null);
        }
        else {
            setExpandedRow(id);
        }
    };   
    
    // Function to return the Audit Log Record Type - Member Value given the # value we are storing.
    function findAuditLogRecordMemberNameByValue(value) {        
        const record = AUDIT_LOG_RECORD_TYPE.find(record => record.value === value);        
        return record ? record.memberName : "Undefined";
    }


    // Component UI    
    
    // Jan 9, 2025: There are some cases where the browser is returning null even though GetReportData API call is returning Gzipped data.
    // Some browsers are hitting a limit for data, it is browser specific, so check if the reportResults is valid or not before trying to display.
    // This will prevent the javascript error flat() displaying in the browser which is not nice.
    if(Array.isArray(reportResults) == false) {
        return(
            <Alert severity="error">
            <AlertTitle>Browser data limit detected.</AlertTitle>
            Unable to load the source data - your current browser data limit was exceeded.  Please contact support if you have questions.
            <br />As an alternative, we recommend running an Insights Search which allows for a refined result set based on a restricted date range.
            </Alert>
        );
    }    
    // Continue with displaying the results.
    // Aug 22, 2024: Updated, concatenate all report results data arrays to display in a sortable table.  
    // Instead of using reduce we use flatten.  When using reduce and concat it uses alot of memory - creating new arrays and copying elements over.
    // Otherwise reportResults is an array of array's of the datasets.  We need one array with all the data as children.            
    var allReportResults = reportResults.flat();    
    
    // Replace with flatten which improves performance by 50%.
    /*
    var allReportResults = reportResults.reduce((accumulator, reportData) => {
        return accumulator.concat(reportData);
    }, []);
    */    
    // We comment out force sort by Id.  Instead we let the source query be the default sort, please and thanks.
    // var sortedAllReportResults = allReportResults.slice().sort((a, b) => a.id - b.id);  // Sort by Id ascending.
    // allReportResults = sortedAllReportResults;


    // CSVLink appears to automatically escape the CSV column with "" to prevent commas from being a problem.
    // If there are any values containing double quotes we replace with single quote to prevent any issues.
    // Prep CSV headers and data for optional CSV export.  
    // Remap our data table columns to CSV headers.    
    const csvHeaders = columns.map((column) => ({
        label: column.label,
        key: column.id
    }));
    // Prep the CSV data.
    const csvData = allReportResults.map((item) => {
        const tempData = {};        
        columns.forEach((column) => {        
            switch(column.id)  {
                case "creationTime":
                    tempData[column.id] = getRecordedTime(item[column.id], selectedTimeZone);  // Convert time to the Tenant's time.
                    break;
                case "recordType":
                    tempData[column.id] = findAuditLogRecordMemberNameByValue(item[column.id]);  // Convert the # to a text value of MSFT Audit Log Record Types.
                    break;
                default:
                    var theString = item[column.id] ? item[column.id].toString() : "";                
                    tempData[column.id] = theString.replace(/"/g, "'");  // Replace any " with '.                        
                    break;
            }
            /*
            if(column.id != "creationTime") {
                var theString = item[column.id] ? item[column.id].toString() : "";                
                tempData[column.id] = theString.replace(/"/g, "'");  // Replace any " with '.                    
            }
            else {
                tempData[column.id] = getTenantsRecordedTime2(item[column.id]);  // Convert time to the Tenant's time.
            }*/
        });
        return tempData;
    });    
    

    return (
        <>

        <br />                 
        <Grid container spacing={2}>                            
            <Grid item xs={6}>
                <div>
                Entries Returned: <b>{allReportResults.length }</b>                
                </div>
            </Grid>
            <Grid item xs={6}>
                <div style={{ direction: 'rtl', textAlign: 'right' }}>
                    <CSVLink data={csvData} headers={csvHeaders} filename={csvFilename}>            
                        <DownloadIcon fontSize="small" />Export
                    </CSVLink>            
                </div>
            </Grid>
        </Grid>


        { /* Display the data source in a sortable table. */ }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>                    
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                        onClick={() => requestSort(column.id)}
                        >
                        {column.label}
                        {sortConfig && sortConfig.key === column.id && (
                            <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                        )}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {                            
                        stableSort(allReportResults, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                            
                        .map((row) => {
                            return (                    
                                <React.Fragment key={row.id}>
                                <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                                    {columns.map((column) => {
                                    const value = row[column.id]; // Access the value using column.id
                                    switch (column.id) {
                                        case 'creationTime':                                                                                            
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordBreak: 'break-all'}} onClick={() => handleRowClick(row.id)}>
                                                {value ? getRecordedTime(value, selectedTimeZone) : value}
                                                </TableCell>
                                            );  
                                            break;
                                        case 'listItemUniqueId':
                                            let listItemUniqueId = "";
                                            if(value && value != "00000000-0000-0000-0000-000000000000") {
                                                listItemUniqueId = value.toString();
                                                return(
                                                    <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordBreak: 'break-all'}} >
                                                        <Link onClick={() => navigate(`/Reports/SharePointItemAuditHistoryReport/${listItemUniqueId}`)} component="button">{listItemUniqueId}</Link>
                                                    </TableCell>
                                                );
                                            }     
                                            else {
                                                return(
                                                    <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordBreak: 'break-all'}} >
                                                        {value}
                                                    </TableCell>
                                                );
                                            }                                           
                                            break;
                                        case 'recordType':
                                            let recordTypeText = "";
                                            if(value) {
                                                recordTypeText = findAuditLogRecordMemberNameByValue(value);    
                                            }
                                            return(
                                                <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordBreak: 'break-all'}} onClick={() => handleRowClick(row.id)}>
                                                    {value ? recordTypeText : value}
                                                </TableCell>
                                            );                                            
                                        default:                                                
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordBreak: 'break-all'}} onClick={() => handleRowClick(row.id)}>                                                    
                                                    {value ? value.toString() : ''}
                                                </TableCell>
                                            );                                                
                                    }    
                                    })}
                                </TableRow>
                                {expandedRow === row.id && (
                                    /* Aug 26, 2024: Updated to now call page to display full audit log details on demand. */
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Accordion>
                                                {/* We don't include an AccordionSummary. */}                                                
                                                <AccordionDetails>                                                                                                        
                                                    <DisplayFullAuditLogDetail tenantId={tenantId} reportType={reportType} auditLogId={row.microsoftId} user={user} userAzureTenantId={userAzureTenantId} />                                                    
                                                </AccordionDetails>                                                
                                            </Accordion>
                                        </TableCell>
                                    </TableRow>
                                )}

                                </React.Fragment>
                            );
                        })
                    }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="span"
                count={allReportResults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />            
        </Paper>

        <Grid container spacing={2} >                            
            <Grid item xs={12}>  
                <br /><br />
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>                        
                        * The dates shown in the table above reflect your browser's detected time zone, to display another time zone select it from the list.&nbsp; Displaying&nbsp; <b>({timeZoneUTCOffsetFormat})</b> &nbsp;  
                    </div>                    
                    <div>
                        <DisplayAndChooseTimezone selectedTimeZone={selectedTimeZone} handleTimeZoneChange={handleTimeZoneChange} />
                    </div>
                </div>                                                                            
            </Grid>
        </Grid>
        
        </>        
    );        
    
}
