// React Components
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

// Material UI Components
import { Grid, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import { Alert, AlertTitle } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import TrialPeriodNag from "../components/common/TrialPeriodNag";
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import SharePointFileActivityDeletion from "../components/reports/SharePointFileActivityDeletion";
import SharePointPageViews from "../components/reports/SharePointPageViews";
import ImageSharePoint from '../imgs/iconSharePoint.svg';
import AuditGrowth from "../components/reports/AuditGrowth";
import PeriodDropDown from "../components/reports/PeriodDropDown";
import TenantSetupRequired from "../components/tenant/TenantSetupRequired";

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../utilities/common-company";
import { NumberBadge } from '../utilities/common-ui-utils';
import { REPORT_PERIOD_LIST, REPORT_ON_PERIOD_1WEEK, REPORT_ON_PERIOD_30DAYS, REPORT_ON_PERIOD_90DAYS, REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE } from "../constants/constants-reportperiods";
import { formatAuditJobRunTimeDateServerTimeToGMT } from '../utilities/common-date-utils';
import TenantDropDown from "../components/reports/TenantDropDown";
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";
import { getHealthStatsByTenantId } from "../utilities/common-healthstats";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'SharePoint Online Reports', link: '/Reports/SharePoint' },
];

const theSectionTitle = "SharePoint Online Reports Dashboard";
const theSectionDesc = "Audit logs for SharePoint and OneDrive capture events such as site and list activity, document activity, site administration, sharing and access requests, user activity, and compliance related events.  Below is a visual representation of your essential SharePoint and OneDrive audit data with links to additional powerful reports to unlock important insights into your data.";
const isAdminPage = false;


/*
Displays the SharePoint Reports home page for Company X.

*/
function ReportsSharepoint(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;

    // Component Constants
    const [company, setCompany] = useState("");
    const [tenantHealthStats, setTenantHealthStats] = useState("");
    const [focusTenantId, setFocusTenantId] = useState("");
    const [focusPeriodId, setFocusPeriodId] = useState(REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE);
    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const navigate = useNavigate();

    const [selectedTenantOption, setSelectedTenantOption] = useState('');
    const [selectedPeriodOption, setSelectedPeriodOption] = useState('');
    const [tabValue, setTabValue] = useState(0);

    // Component Functions
    // Load TenantHealthStats given the tenandId.
    async function fetchTenantHealthStats(theTenantId) {
        setLoading(true);
        try {

            // Check if current user has Report Reader access to the specified tenant.
            var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, theTenantId, ROLE_CODE_TENANTREPORTREADER);
            if (!user || !checkReportAccessForTenant) {
                setAccessDenied(true);
            }
            else {
                setAccessDenied(false);
            }

            // We attempt to load the tenant health stats (which lets us see if the tenant is pending still etc).
            var response2 = await getHealthStatsByTenantId(theTenantId, 2, user);
            if (response2) {
                setTenantHealthStats(response2);
            }

        }
        catch (e) {
            console.log("ERROR: ReportSharePoint.fetchTenantHealthStats");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        /*
        Check if companyId is specified, if not then load current User's company.                
        */
        async function fetchCompanyData() {
            setLoading(true);
            try {
                if (user && user.companyId) {
                    // The companyId was specified.
                    var response1 = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response1) {
                        // Company is valid.  We reference Company using response because setting the Company may take some time.  
                        setCompany(response1);
                        // Set the default to the first item in the list if there is any.
                        if (response1.tenantList && response1.tenantList.length > 0) {
                            setSelectedTenantOption(response1.tenantList[0]);  // Set to the first item in the list.
                            // Set the default period in the drop down list (we default to 30 days).                            
                            setSelectedPeriodOption(REPORT_PERIOD_LIST[REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE]);
                            fetchTenantHealthStats(response1.tenantList[0].id);
                            setFocusTenantId(response1.tenantList[0].id);
                        }
                        // Else the tenantList is empty.  Nothing in the selection list to set.                                        
                    }
                }
            }
            catch (e) {
                console.log("ERROR: ReportSharePoint.fetchCompanyData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }

        fetchCompanyData();
    }, []);
    // So at this point - the value for selectedTenantOption is either empty or the tenant object in question.  
    // We can use the selectedTenantOption as what we need to load the reports for (tenant object).  


    // Tab panel.
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        value: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Event handlers for drop down lists.
    const handleTenantOptionChange = (event) => {
        setSelectedTenantOption(event.target.value);
        fetchTenantHealthStats(event.target.value.id);
        setFocusTenantId(event.target.value.id);
    };
    const handlePeriodOptionChange = (event) => {
        setSelectedPeriodOption(event.target.value);
        setFocusPeriodId(event.target.value.id);
    };

    // Component UI
    if (loading) {
        console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    //if (selectedTenantOption && tenantHealthStats.status >= 1) {
    // Get the total audit record count.
    var totalAuditRecords = 0;
    if (tenantHealthStats.totalSharePointAuditRecords) {
        totalAuditRecords = tenantHealthStats.totalSharePointAuditRecords;
    }

    // A valid Tenant is chosen from the textfield selection drop down list.
    return (
        <>
            <TrialPeriodNag company={company}></TrialPeriodNag>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><img src={ImageSharePoint} alt="SharePoint Reports" />
                </div>
            </div>
            <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />

            <div>
                <Grid container spacing={2}>
                    {
                        accessDenied &&
                        <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}><AccessDenied /></Grid>
                    }

                    {!selectedTenantOption &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <Alert severity="error">
                                    <AlertTitle>Your Company has not yet completed the setup process or you have not yet specified a valid Tenant.</AlertTitle>
                                </Alert>
                            </Grid>)
                    }
                    {tenantHealthStats.status == 0 &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <TenantSetupRequired />
                            </Grid>
                        )
                    }
                    {selectedTenantOption &&
                        (
                            <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                <Grid item>
                                    <TenantDropDown
                                        user={user}
                                        company={company}
                                        handleTenantOptionChange={handleTenantOptionChange}
                                        selectedTenantOption={selectedTenantOption}></TenantDropDown>
                                </Grid>
                            </Grid>
                        )
                    }

                    {!accessDenied && selectedTenantOption && tenantHealthStats.status >= 1 ? (
                        <>
                            <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                <Grid item>
                                    <PeriodDropDown selectedPeriodOption={selectedPeriodOption}
                                        handlePeriodOptionChange={handlePeriodOptionChange} />
                                </Grid>
                            </Grid>
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                Audit data last updated on: {formatAuditJobRunTimeDateServerTimeToGMT(tenantHealthStats.sharePointAuditLastSuccessfulRunTime)}
                                <br />
                            </Grid>
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <AuditGrowth tenantId={focusTenantId} periodToUse={focusPeriodId} reportType="SharePoint" />
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%', overflowWrap: 'break-word' }}>
                                    <SharePointFileActivityDeletion user={user} companyId={company.id} tenantId={focusTenantId} periodToUse={focusPeriodId} loadData={false} reportOnDate={new Date()} displayDataSource={0} filterByColumn={"operation"} />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <SharePointPageViews
                                            user={user}
                                            companyId={company.id}
                                            tenantId={focusTenantId}
                                            periodToUse={focusPeriodId}
                                            loadData={false}
                                            filterByColumn={"Operation"} />
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Total Audit Records</Typography>
                                        <br />
                                        <TableContainer>
                                            <Table style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit' }}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{ border: 'none', padding: 2 }}>SharePoint</TableCell>
                                                        <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={totalAuditRecords} bgcolor='#ff6384' txtcolor='white' /></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 333, width: "66%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Search your SharePoint Audit Records</Typography>
                                        <br />
                                        <div style={{ textAlign: 'center' }}>
                                            <ManageSearchIcon style={{ fontSize: '3rem' }} />&nbsp;
                                            <Link onClick={() => navigate(`/Reports/InsightsSearch/SharePoint`)} component="button">Configure and run your Insights Search.</Link>
                                        </div>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>

                                {/* Tab Panel. */}
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="SharePoint Reports Tab">
                                            <Tab label="Activity Reports" {...a11yProps(0)} />
                                            <Tab label="User Reports" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={tabValue} index={0}>
                                        <Link onClick={() => navigate(`/Reports/SharePointItemAuditHistoryReport`)} component="button">Get Audit History for an Item</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharepointAuditHistory/1`)} component="button">View SharePoint Audit History (to be accessed from SPO)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointAllActivityBySiteReport/${focusTenantId}`)} component="button">All Activity by Site Collection, Location or File</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointSitePermsAndChangeHistoryReport/${focusTenantId}`)} component="button">Site Permissions Change History</Link><br />                                        
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityDeletionReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">SharePoint File Activity - Deletions (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityDeletionReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/siteUrl`)} component="button">SharePoint File Activity - Deletion by Site (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityDeletionReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/operation`)} component="button">SharePoint File Activity - Deletions (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityDeletionReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/siteUrl`)} component="button">SharePoint File Activity - Deletion by Site (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityViewAndDownloadReport/${focusTenantId}/${REPORT_ON_PERIOD_1WEEK}/operation`)} component="button">SharePoint File Activity - Views and Downloads (Last 7 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityViewAndDownloadReport/${focusTenantId}/${REPORT_ON_PERIOD_1WEEK}/siteUrl`)} component="button">SharePoint File Activity - Views and Downloads by Site (Last 7 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityViewAndDownloadReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">SharePoint File Activity - Views and Downloads (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityViewAndDownloadReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/siteUrl`)} component="button">SharePoint File Activity - Views and Downloads by Site (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditReport/${focusTenantId}/${REPORT_ON_PERIOD_1WEEK}/operation`)} component="button">SharePoint File Activity - New Files and Edits (Last 7 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditAndFileSyncReport/${focusTenantId}/${REPORT_ON_PERIOD_1WEEK}/operation`)} component="button">SharePoint File Activity - New Files and Edits - Include OneDrive Sync (Last 7 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditReport/${focusTenantId}/${REPORT_ON_PERIOD_1WEEK}/siteUrl`)} component="button">SharePoint File Activity - New Files and Edits by Site (Last 7 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditAndFileSyncReport/${focusTenantId}/${REPORT_ON_PERIOD_1WEEK}/siteUrl`)} component="button">SharePoint File Activity - New Files and Edits by Site - Include OneDrive Sync (Last 7 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">SharePoint File Activity - New Files and Edits (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditAndFileSyncReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">SharePoint File Activity - New Files and Edits - Include OneDrive Sync (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/siteUrl`)} component="button">SharePoint File Activity - New Files and Edits by Site (Last 30 days)</Link><br />                                        
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityNewAndEditAndFileSyncReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/siteUrl`)} component="button">SharePoint File Activity - New Files and Edits by Site - Include OneDrive Sync (Last 30 days)</Link><br />                  
                                        <Link onClick={() => navigate(`/Reports/SharePointPageViewsReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">SharePoint Page Views (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointPageViewsReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/userId`)} component="button">SharePoint Page Views by User (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointPageViewsReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/operation`)} component="button">SharePoint Page Views (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointPageViewsReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/userId`)} component="button">SharePoint Page Views by User (Last 3 months)</Link><br />
                                        {/*
                                        // We deprecate use of SharePointFileActivity since it loads too much data.
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">SharePoint File Activity (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/operation`)} component="button">SharePoint File Activity (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/siteUrl`)} component="button">SharePoint File Activity by Site Collection (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointFileActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/siteUrl`)} component="button">SharePoint File Activity by Site Collection (Last 3 months)</Link><br />
                                        */}
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        <Link onClick={() => navigate(`/Reports/SharePointAllActivityByUserReport/${focusTenantId}`)} component="button">All Activity by User</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointDeletionsByUserReport/${focusTenantId}`)} component="button">Documents and Folders Deleted by User</Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointCreationsByUserReport/${focusTenantId}`)} component="button">Documents and Folders Created by User<br /></Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointEditsByUserReport/${focusTenantId}`)} component="button">Documents and Folders Edited by User<br /></Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointViewsByUserReport/${focusTenantId}`)} component="button">Documents Viewed by User<br /></Link><br />
                                        <Link onClick={() => navigate(`/Reports/SharePointAllActivityByIPAddressReport/${focusTenantId}`)} component="button">All Activity by IP Address</Link><br />
                                    </TabPanel>
                                </Box>

                            </Grid>
                        </>
                    ) :
                        (
                            <>
                                <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                    <ProgressBar message="Loading ..." loading={true} />
                                </Grid>
                            </>
                        )
                    }
                    <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                        <br />
                        Need a custom or new report?  Provide us with your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Product Feedback</Link>.
                        <br />* Unless otherwise specified as UTC, graphs on page are using your browser's local timezone.
                    </Grid>
                </Grid>
            </div>
        </>)

}
export default ReportsSharepoint;