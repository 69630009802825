// React Components
import React from 'react';
import { Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

// Audit Vault Pages
import { PageLayout } from "./components/PageLayout";
import MyCompany from './pages/MyCompany';
import MyTenant from "./pages/MyTenant";
import MyHome from "./pages/MyHome";

const MyBilling = React.lazy(() => import('./pages/MyBilling'));
const MyProfile = React.lazy(() => import('./pages/MyProfile'));
const UpdateCompany = React.lazy(() => import('./pages/UpdateCompany'));
const UpdateTenant = React.lazy(() => import('./pages/UpdateTenant'));
const CreateTenant = React.lazy(() => import('./pages/CreateTenant'));
const MyTenantValidate = React.lazy(() => import('./pages/MyTenantValidate'));
const MyTenantStats = React.lazy(() => import('./pages/MyTenantStats'));
const GrantAccess = React.lazy(() => import('./pages/GrantAccess'));
const SupportHome = React.lazy(() => import('./pages/SupportHome'));
const MessageCenter = React.lazy(() => import('./pages/MessageCenter'));
const MyCompanyAssignRole = React.lazy(() => import('./pages/MyCompanyAssignRole'));
const MyCompanyRoles = React.lazy(() => import('./pages/MyCompanyRoles'));
const MyTenantRoleForm = React.lazy(() => import('./components/role/TenantRoleForm'));
const TenantRoleAssignUserForm = React.lazy(() => import('./components/role/TenantRoleAssignUserForm2'));
const ProductFeedback = React.lazy(() => import('./pages/ProductFeedback'));
const SupportTicket = React.lazy(() => import('./pages/SupportTicket'));
const HelpCenter = React.lazy(() => import('./pages/HelpCenter'));
const MyCompanyUsers = React.lazy(() => import('./pages/MyCompanyUsers'));
const GrantHistoricalImportConsent = React.lazy(() => import('./pages/GrantHistoricalImportConsent'));
const MyTenantSetupHistoricalImport = React.lazy(() => import('./pages/MyTenantSetupHistoricalImport'));


// Company Setup
const MyCompanySetupCreate = React.lazy(() => import('./pages/companysetup/MyCompanySetupCreate'));
const MyCompanySetupUpdate = React.lazy(() => import('./pages/companysetup/MyCompanySetupUpdate'));
const MyCompanySetupComplete = React.lazy(() => import('./pages/companysetup/MyCompanySetupComplete'));


// Report related pages.
import Reports from './pages/Reports';
import ReportsSharepoint from "./pages/ReportsSharepoint";
import ReportsExchange from "./pages/ReportsExchange";
import ReportsEntraID from "./pages/ReportsEntraID";
import ReportsTeams from "./pages/ReportsTeams";
import ReportsSearch from "./pages/ReportsSearch";
import ReportsThreatIntelligence from "./pages/ReportsThreatIntelligence";

const ReportsDiagnostics = React.lazy(() => import('./pages/ReportsDiagnostics'));
const ReportsSearchResultsCompleted = React.lazy(() => import('./pages/ReportsSearchResultsCompleted'));
const ReportsSharePointFileActivity = React.lazy(() => import('./pages/ReportsSharePointFileActivity'));
const ReportsSharePointFileActivityNewAndEdit = React.lazy(() => import('./pages/ReportsSharePointFileActivityNewAndEdit'));
const ReportsSharePointFileActivityNewAndEditAndFileSync = React.lazy(() => import('./pages/ReportsSharePointFileActivityNewAndEditAndFileSync'));
const ReportsSharePointFileActivityDeletion = React.lazy(() => import('./pages/ReportsSharePointFileActivityDeletion'));
const ReportsSharePointFileActivityViewAndDownload = React.lazy(() => import('./pages/ReportsSharePointFileActivityViewAndDownload'));
const ReportsSharePointPageViews = React.lazy(() => import('./pages/ReportsSharePointPageViews'));
const ReportsFromSpoViewSharePointAuditHistory = React.lazy(() => import('./pages/ReportsFromSpoViewSharePointAuditHistory'));
const ReportsSharePointAllActivityByUser = React.lazy(() => import('./pages/ReportsSharePointAllActivityByUser'));
const ReportsSharePointAllActivityByIPAddress = React.lazy(() => import('./pages/ReportsSharePointAllActivityByIPAddress'));
const ReportsSharePointAllActivityBySite = React.lazy(() => import('./pages/ReportsSharePointAllActivityBySite'));
const ReportsSharePointDeletionsByUser = React.lazy(() => import('./pages/ReportsSharePointDeletionsByUser'));
const ReportsSharePointCreationsByUser = React.lazy(() => import('./pages/ReportsSharePointCreationsByUser'));
const ReportsSharePointEditsByUser = React.lazy(() => import('./pages/ReportsSharePointEditsByUser'));
const ReportsSharePointViewsByUser = React.lazy(() => import('./pages/ReportsSharePointViewsByUser'));
const ReportsSharePointSitePermsAndChangeHistory = React.lazy(() => import('./pages/ReportsSharePointSitePermsAndChangeHistory'));

const ReportsExchangeAllActivityByUser = React.lazy(() => import('./pages/ReportsExchangeAllActivityByUser'));
const ReportsExchangeAllActivityByIPAddress = React.lazy(() => import('./pages/ReportsExchangeAllActivityByIPAddress'));
const ReportsExchangeDeletedItems = React.lazy(() => import('./pages/ReportsExchangeDeletedItems'));
const ReportsExchangeMailboxesCreated = React.lazy(() => import('./pages/ReportsExchangeMailboxesCreated'));
const ReportsExchangeMailboxesDeleted = React.lazy(() => import('./pages/ReportsExchangeMailboxesDeleted'));
const ReportsExchangeMailboxesCreatedAndDeletedByUser = React.lazy(() => import('./pages/ReportsExchangeMailboxesCreatedAndDeletedByUser'));

const ReportsEntraIDAllActivityByUser = React.lazy(() => import('./pages/ReportsEntraIDAllActivityByUser'));
const ReportsEntraIDAllActivityByIPAddress = React.lazy(() => import('./pages/ReportsEntraIDAllActivityByIPAddress'));
const ReportsEntraIDFailedLogins = React.lazy(() => import('./pages/ReportsEntraIDFailedLogins'));
const ReportsEntraIDSuccessfulLogins = React.lazy(() => import('./pages/ReportsEntraIDSuccessfulLogins'));
const ReportsEntraIDGroupAndUserActivity = React.lazy(() => import('./pages/ReportsEntraIDGroupAndUserActivity'));
const ReportsEntraIDGroupAndUserActivityByUser = React.lazy(() => import('./pages/ReportsEntraIDGroupAndUserActivityByUser'));

const ReportsThreatSharePointFileDownloads = React.lazy(() => import('./pages/ReportsThreatSharePointFileDownloads'));
const ReportsThreatSharePointFileAccesses = React.lazy(() => import('./pages/ReportsThreatSharePointFileAccesses'));
const ReportsThreatSharePointFileOrFolderDeletions = React.lazy(() => import('./pages/ReportsThreatSharePointFileOrFolderDeletions'));
const ReportsThreatSharePointPermsUpdateAddedToGroup = React.lazy(() => import('./pages/ReportsThreatSharePointPermsUpdateAddedToGroup'));
const ReportsThreatExchangeDeletedItems = React.lazy(() => import('./pages/ReportsThreatExchangeDeletedItems'));
const ReportsThreatEntraIDFailedLoginsInvalidPassword = React.lazy(() => import('./pages/ReportsThreatEntraIDFailedLoginsInvalidPassword'));
const ReportsThreatEntraIDFailedLoginsFailedMFA = React.lazy(() => import('./pages/ReportsThreatEntraIDFailedLoginsFailedMFA'));
const ReportsThreatEntraIDSuccessfulLoginsDifferentIPs = React.lazy(() => import('./pages/ReportsThreatEntraIDSuccessfulLoginDifferentIPs'));

const ReportsTeamsAllActivity = React.lazy(() => import('./pages/ReportsTeamsAllActivity'));
const ReportsTeamsAllActivityByUser = React.lazy(() => import('./pages/ReportsTeamsAllActivityByUser'));
const ReportsTeamsAllActivityByIPAddress = React.lazy(() => import('./pages/ReportsTeamsAllActivityByIPAddress'));
const ReportsTeamsAllActivityByTeamOrChannel = React.lazy(() => import('./pages/ReportsTeamsAllActivityByTeamOrChannel'));

const ReportsSharePointByListItemUniqueId = React.lazy(() => import('./pages/ReportsSharePointByListItemUniqueId'));
const ReportsFirstAuditImportHistory = React.lazy(() => import('./pages/ReportsFirstAuditImportHistory'));
const ReportsHistoricalAuditHistory = React.lazy(() => import('./pages/ReportsHistoricalAuditHistory'));
const ReportsUserActivity = React.lazy(() => import('./pages/ReportsUserActivity'));
const ReportsThreatScheduleReport = React.lazy(() => import('./pages/ReportsThreatScheduleReport'));

// Admin Pages
const AdminHome = React.lazy(() => import('./pages/AdminHome'));
const UpdateCompanyAdmin = React.lazy(() => import('./pages/admin/UpdateCompanyAdmin'));
const CreateTenantAdmin = React.lazy(() => import('./pages/admin/CreateTenantAdmin'));
const TenantsAdmin = React.lazy(() => import('./pages/admin/TenantsAdmin'));
const AddCompany = React.lazy(() => import('./pages/admin/AddCompany'));
const CompanyListAdmin = React.lazy(() => import('./pages/admin/CompanyListAdmin'));
const UpdateTenantAdmin = React.lazy(() => import('./pages/admin/UpdateTenantAdmin'));
const MessageCenterAdmin = React.lazy(() => import('./pages/admin/MessageCenterAdmin'));
const NewMessage = React.lazy(() => import('./pages/admin/NewMessage'));
const EditMessage = React.lazy(() => import('./pages/admin/EditMessage'));
const SystemAdminUsersList = React.lazy(() => import('./pages/admin/SystemAdminUsersList'));
const SystemAdinAssignRole = React.lazy(() => import('./pages/admin/SystemAdminAssignRole'));
const HeartbeatForSearch = React.lazy(() => import('./pages/admin/HeartbeatForSearch'));
const NewSearchOrchestrator = React.lazy(() => import('./pages/admin/NewSearchOrchestrator'));
const EditSearchOrchestrator = React.lazy(() => import('./pages/admin/EditSearchOrchestrator'));
const ViewSearchOrchestratorLogs = React.lazy(() => import('./pages/admin/ViewSearchOrchestratorLogs'));
const SystemEmailsList = React.lazy(() => import('./pages/admin/SystemEmailsList'));
const SystemEmailForm = React.lazy(() => import('./pages/admin/SystemEmailForm'));
const SystemJobsList = React.lazy(() => import('./pages/admin/SystemJobsList'));
const SystemOrchestratorsList = React.lazy(() => import('./pages/admin/SystemOrchestratorsList'));
const JobOrchestratorForm = React.lazy(() => import('./pages/admin/JobOrchestratorForm'));
const AdminUserActivityReport = React.lazy(() => import('./pages/admin/AdminUserActivityReport'));
const AdminSystemDiagnosticsReport = React.lazy(() => import('./pages/admin/AdminSystemDiagnosticsReport'));


// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// Audit Vault Components
import ServiceIsDown from './components/common/ServiceIsDown';

// Audit Vault Common
import { getAuditVaultUser } from "./utilities/common-user-utils";
import { createSystemEvent } from "./utilities/common-sysEvents-util";
import { SYSEVENT_LOGIN } from "./constants/constants-systemEvents";



// Display page not found or access denied details.
function NotFound({ location }) {
    return (
        <>
            <Alert severity="error">
                <AlertTitle>
                    Sorry, Page Not Found
                    <br />
                    <br />It appears the page you are requesting is not found or you do not have permissions to access this page.
                    <br />The requested url: {location.pathname}
                </AlertTitle>
                <br />For permission inquiries: please raise a support ticket to your local Company Administrator.
                <br />If you believe you are seeing this page in error: please raise a regular support ticket.
            </Alert>
        </>
    );
}


function App() {

    // If PROD, disable all console.log messages
    if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
        console.log = function no_console() { };
    }

    const location = useLocation();
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [user, setUser] = useState("");
    const [userRole, setUserRole] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [openCompanyNag, setOpenCompanyNag] = useState(true);

    async function fetchAuditVaultUserData(accounts, isAuthenticated, user, setLoading, loading, setServiceIsDownError) {

        if (loading && isAuthenticated && user == '') {

            try {
                const response = await getAuditVaultUser(isAuthenticated, accounts[0]);
                if (response) {
                    setUser(response);
                    if (response.isSystemAdmin) {
                        setUserRole(0);
                    }
                    else if (response.isCompanyAdmin) {
                        setUserRole(1);
                    }
                    else {
                        if (response.permissions.length > 0)
                            setUserRole(response.permissions[0].roleId);
                        else
                            setUserRole(5);
                    }
                }
            }
            catch (err) {
                console.log("ERROR: App.js.fetchAuditVaultUserData")
                console.log(err);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }

    }

    useEffect(() => {
        fetchAuditVaultUserData(accounts, isAuthenticated, user, setLoading, loading, setServiceIsDownError);
        createSystemEvent(user, SYSEVENT_LOGIN, "");
    }, [setUser, user]);

    if (user == '' && isAuthenticated) {
        fetchAuditVaultUserData(accounts, isAuthenticated, user, setLoading, loading, setServiceIsDownError);
    }

    /*
        SystemAdministrator = 0,
        CompanyAdministrator = 1,
        TenantAdministrator = 2,
        TenantReportReader = 3,
        TenantViewer = 4,
        NoAccess = 5,
    */

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    // Added to prevent user from being blank if we render the Route before we are finished loading the User.  
    // Once the user is non-blank and the loading is false, only then render the regular page routes.
    if (loading || !user) {
        // Display a loading indicator
        return (
            <PageLayout user={user}
                setUser={setUser}
                isAuthenticated={isAuthenticated}
                userRole={userRole}>

                <UnauthenticatedTemplate>
                    <Routes>
                        <Route path="/" element={<MyCompany openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                        <Route path="/mycompany" element={<MyCompany openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                        <Route path="/reports/sharepointaudithistory/:id" element={<Suspense fallback="Loading ... "><ReportsFromSpoViewSharePointAuditHistory /></Suspense>} />
                        <Route path="/mycompanysetupcreate" element={<Suspense fallback="Loading ..."><MyCompanySetupCreate /></Suspense>} />
                    </Routes>
                </UnauthenticatedTemplate>
            </PageLayout>
        );
    }

    return (
        <PageLayout user={user}
            setUser={setUser}
            isAuthenticated={isAuthenticated}
            userRole={userRole}>

            <AuthenticatedTemplate>
                <Routes>

                    {isAuthenticated && userRole <= 5 && (
                        // Default access.
                        <>
                            <Route path="/" element={<MyHome user={user} serviceIsDownError={serviceIsDownError} setServiceIsDownError={setServiceIsDownError} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/myhome" element={<MyHome user={user} serviceIsDownError={serviceIsDownError} setServiceIsDownError={setServiceIsDownError} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/myprofile" element={<Suspense fallback="Loading ... "><MyProfile user={user} /></Suspense>} />
                            <Route path="/reports/sharepointaudithistory/:id" element={<Suspense fallback="Loading ... "><ReportsFromSpoViewSharePointAuditHistory user={user} /></Suspense>} />
                            <Route path="/support/messagecenter" element={<Suspense fallback="Loading ... "><MessageCenter user={user} /></Suspense>} />
                            <Route path="/support/supporthome" element={<Suspense fallback="Loading ..."><SupportHome user={user} /></Suspense>} />
                            <Route path="/support/productfeedback" element={<Suspense fallback="Loading ... "><ProductFeedback user={user} /></Suspense>} />
                            <Route path="/support/helpcenter" element={<Suspense fallback="Loading ... "><HelpCenter user={user} /></Suspense>} />
                            <Route path="/support/supportticket" element={<Suspense fallback="Loading ... "><SupportTicket user={user} /></Suspense>} />
                            <Route path="/tenant-create" element={<Suspense fallback="Loading ... "><CreateTenant user={user} /></Suspense>} />
                            <Route path="/Tenant/MyTenant" element={<MyTenant user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/grantaccess" element={<Suspense fallback="Loading ..."><GrantAccess user={user} /></Suspense>} />
                            <Route path="/grantHistoricalImportConsent" element={<Suspense fallback="Loading ... "><GrantHistoricalImportConsent user={user} /></Suspense>} />
                            <Route path="/mycompanysetupcreate" element={<Suspense fallback="Loading ..."><MyCompanySetupCreate user={user} /></Suspense>} />
                            <Route path="/mycompanysetupupdate" element={<Suspense fallback="Loading ..."><MyCompanySetupUpdate user={user} setUser={setUser} /></Suspense>} />
                            <Route path="/mycompanysetupcomplete" element={<Suspense fallback="Loading ..."><MyCompanySetupComplete user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} setUser={setUser} /></Suspense>} />
                        </>
                    )}

                    {isAuthenticated && userRole <= 3 && (
                        // Tenant Report Reader and Up.
                        <>
                            <Route path="/reports/reportshome" element={<Reports user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/sharepoint" element={<ReportsSharepoint user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/exchange" element={<ReportsExchange user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/entraID" element={<ReportsEntraID user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/teams" element={<ReportsTeams user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/insightssearch/:defaultWorkload" element={<ReportsSearch user={user} />} />
                            <Route path="/reports/threatintelligence" element={<ReportsThreatIntelligence user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/Reports/ThreatIntelligence/ScheduleReport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatScheduleReport user={user} /></Suspense>} />
                            <Route path="/Reports/ThreatIntelligence/ScheduleReport/:tenantId/:jobId" element={<Suspense fallback={"loading ..."}><ReportsThreatScheduleReport user={user} /></Suspense>} />
                            <Route path="/reports/viewsearchresults/:searchJobId" element={<Suspense fallback={"loading ..."}><ReportsSearchResultsCompleted user={user} /></Suspense>} />
                            <Route path="/reports/sharepointfileactivitynewandeditreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsSharePointFileActivityNewAndEdit user={user} /></Suspense>} />
                            <Route path="/reports/sharepointfileactivitynewandeditandfilesyncreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsSharePointFileActivityNewAndEditAndFileSync user={user} /></Suspense>} />
                            <Route path="/reports/sharepointfileactivitydeletionreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsSharePointFileActivityDeletion user={user} /></Suspense>} />
                            <Route path="/reports/sharepointfileactivityviewanddownloadreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsSharePointFileActivityViewAndDownload user={user} /></Suspense>} />
                            <Route path="/reports/sharepointfileactivityreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsSharePointFileActivity user={user} /></Suspense>} />
                            <Route path="/reports/sharepointpageviewsreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsSharePointPageViews user={user} /></Suspense>} />
                            <Route path="/reports/sharepointitemaudithistoryreport" element={<Suspense fallback={"loading ..."}><ReportsSharePointByListItemUniqueId user={user} /></Suspense>} />
                            <Route path="/reports/sharepointitemaudithistoryreport/:id" element={<Suspense fallback={"loading ..."}><ReportsSharePointByListItemUniqueId user={user} /></Suspense>} />
                            <Route path="/reports/sharepointallactivitybyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointAllActivityByUser user={user} /></Suspense>} />
                            <Route path="/reports/sharepointallactivitybyipaddressreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointAllActivityByIPAddress user={user} /></Suspense>} />
                            <Route path="/reports/sharepointallactivitybysitereport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointAllActivityBySite user={user} /></Suspense>} />
                            <Route path="/reports/sharepointdeletionsbyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointDeletionsByUser user={user} /></Suspense>} />
                            <Route path="/reports/sharepointcreationsbyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointCreationsByUser user={user} /></Suspense>} />
                            <Route path="/reports/sharepointeditsbyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointEditsByUser user={user} /></Suspense>} />
                            <Route path="/reports/sharepointviewsbyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointViewsByUser user={user} /></Suspense>} />
                            <Route path="/reports/sharepointsitepermsandchangehistoryreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsSharePointSitePermsAndChangeHistory user={user} /></Suspense>} />
                            <Route path="/reports/exchangeallactivitybyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsExchangeAllActivityByUser user={user} /></Suspense>} />
                            <Route path="/reports/exchangeallactivitybyipaddressreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsExchangeAllActivityByIPAddress user={user} /></Suspense>} />
                            <Route path="/reports/exchangedeleteditemsreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsExchangeDeletedItems user={user} /></Suspense>} />
                            <Route path="/reports/exchangemailboxescreatedreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsExchangeMailboxesCreated user={user} /></Suspense>} />
                            <Route path="/reports/exchangemailboxesdeletedreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsExchangeMailboxesDeleted user={user} /></Suspense>} />
                            <Route path="/reports/exchangemailboxescreatedordeletedbyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsExchangeMailboxesCreatedAndDeletedByUser user={user} /></Suspense>} />
                            <Route path="/reports/entraidallactivitybyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsEntraIDAllActivityByUser user={user} /></Suspense>} />
                            <Route path="/reports/entraidallactivitybyipaddressreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsEntraIDAllActivityByIPAddress user={user} /></Suspense>} />
                            <Route path="/reports/entraidfailedloginsreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsEntraIDFailedLogins user={user} /></Suspense>} />
                            <Route path="/reports/entraidsuccessfulloginsreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsEntraIDSuccessfulLogins user={user} /></Suspense>} />
                            <Route path="/reports/entraidgroupanduseractivityreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsEntraIDGroupAndUserActivity user={user} /></Suspense>} />
                            <Route path="/reports/entraidgroupanduseractivitybyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsEntraIDGroupAndUserActivityByUser user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointfiledownloadsreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatSharePointFileDownloads user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointfiledownloadsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback={"loading ..."}><ReportsThreatSharePointFileDownloads user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointfileaccessesreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatSharePointFileAccesses user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointfileaccessesreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback="loading ..."><ReportsThreatSharePointFileAccesses user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointfileorfolderdeletionsreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatSharePointFileOrFolderDeletions user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointfileorfolderdeletionsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback={"loading ..."}><ReportsThreatSharePointFileOrFolderDeletions user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointpermsupdateaddedtogroupreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatSharePointPermsUpdateAddedToGroup user={user} /></Suspense>} />
                            <Route path="/reports/threatsharepointpermsupdateaddedtogroupreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback={"loading ..."}><ReportsThreatSharePointPermsUpdateAddedToGroup user={user} /></Suspense>} />
                            <Route path="/reports/threatexchangedeleteditemsreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatExchangeDeletedItems user={user} /></Suspense>} />
                            <Route path="/reports/threatexchangedeleteditemsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback={"loading ..."}><ReportsThreatExchangeDeletedItems user={user} /></Suspense>} />
                            <Route path="/reports/threatentraidfailedloginsinvalidpasswordreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatEntraIDFailedLoginsInvalidPassword user={user} /></Suspense>} />
                            <Route path="/reports/threatentraidfailedloginsinvalidpasswordreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback={"loading ..."}><ReportsThreatEntraIDFailedLoginsInvalidPassword user={user} /></Suspense>} />
                            <Route path="/reports/threatentraidfailedloginsfailedmfareport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatEntraIDFailedLoginsFailedMFA user={user} /></Suspense>} />
                            <Route path="/reports/threatentraidfailedloginsfailedmfareport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback={"loading ..."}><ReportsThreatEntraIDFailedLoginsFailedMFA user={user} /></Suspense>} />
                            <Route path="/reports/threatentraidsuccessfulloginsdifferentipsreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsThreatEntraIDSuccessfulLoginsDifferentIPs user={user} /></Suspense>} />
                            <Route path="/reports/threatentraidsuccessfulloginsdifferentipsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<Suspense fallback={"loading ..."}><ReportsThreatEntraIDSuccessfulLoginsDifferentIPs user={user} /></Suspense>} />
                            <Route path="/reports/diagnosticsreport/:id" element={<Suspense fallback={"loading ..."}><ReportsDiagnostics user={user} /></Suspense>} />
                            <Route path="/reports/historical-audit-history/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsHistoricalAuditHistory user={user} /></Suspense>} />
                            <Route path="/reports/first-audit-import/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsFirstAuditImportHistory user={user} /></Suspense>} />
                            <Route path="/reports/teamsallactivityreport/:tenantId/:periodToUse/:filterByColumn" element={<Suspense fallback={"loading ..."}><ReportsTeamsAllActivity user={user} /></Suspense>} />
                            <Route path="/reports/teamsallactivitybyuserreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsTeamsAllActivityByUser user={user} /></Suspense>} />
                            <Route path="/reports/teamsallactivitybyipaddressreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsTeamsAllActivityByIPAddress user={user} /></Suspense>} />
                            <Route path="/reports/teamsallactivitybyteamandchannelreport/:tenantId" element={<Suspense fallback={"loading ..."}><ReportsTeamsAllActivityByTeamOrChannel user={user} /></Suspense>} />
                        </>
                    )}

                    {isAuthenticated && userRole <= 2 && (
                        // Tenant Admin and Up.
                        <>
                            <Route path="/mytenant-validate" element={<Suspense fallback="Loading ..."><MyTenantValidate user={user} setUser={setUser} /></Suspense>} />
                            <Route path="/mytenant-validate/:id" element={<Suspense fallback="Loading ..."><MyTenantValidate user={user} setUser={setUser} /></Suspense>} />
                            <Route path="/mytenant-roles/:tenantId/:roleId" element={<Suspense fallback="Loading ..."><MyTenantRoleForm user={user} /></Suspense>} />
                            <Route path="/mytenant-roles-assignusers/:tenantId/:roleId" element={<Suspense fallback="Loading ..."><TenantRoleAssignUserForm user={user} /></Suspense>} />
                            <Route path="/tenant-update/:id" element={<Suspense fallback={"loading ..."}><UpdateTenant user={user} setUser={setUser} /></Suspense>} />
                            <Route path="/tenant-stats/:id" element={<Suspense fallback={"loading ..."}><MyTenantStats user={user} /></Suspense>} />
                            <Route path="/tenant-historical-import/:tenantId" element={<Suspense fallback={"loading ..."}><MyTenantSetupHistoricalImport user={user} /></Suspense>} />
                        </>

                    )}

                    {isAuthenticated && userRole <= 1 && (
                        // Company Admin and Up.
                        <>
                            <Route path="/mycompany" element={<MyCompany user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/mycompany/company-update/:id" element={<Suspense fallback="Loading ..."><UpdateCompany user={user} /></Suspense>} />
                            <Route path="/mybilling" element={<Suspense fallback="Loading ..."><MyBilling user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} /></Suspense>} />
                            <Route path="/mycompany-roles" element={<Suspense fallback="Loading ..."><MyCompanyRoles user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} /></Suspense>} />
                            <Route path="/mycompany-assign-role" element={<Suspense fallback="Loading ..."><MyCompanyAssignRole user={user} /></Suspense>} />
                            <Route path="/mycompany-assign-role/:tenantId/:roleId" element={<Suspense fallback="Loading ..."><MyCompanyAssignRole user={user} /></Suspense>} />
                            <Route path="/mycompany-users" element={<Suspense fallback="Loading ..."><MyCompanyUsers user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} /></Suspense>} />
                            <Route path="/mycompany/user-activity-logs" element={<Suspense fallback="Loading ..."><ReportsUserActivity user={user} /></Suspense>} />
                        </>
                    )}

                    {isAuthenticated && user.isSystemAdmin && (
                        // System Admin Access Only.
                        <>
                            <Route path="/admin/tenant-update/:id" element={<Suspense fallback="Loading ..."><UpdateTenantAdmin user={user} /></Suspense>} />
                            <Route path="/admin/tenant-create/:companyId" element={<Suspense fallback="Loading ..."><CreateTenantAdmin user={user} /></Suspense>} />
                            <Route path="/admin/adminhome" element={<Suspense fallback="Loading ..."><AdminHome user={user} /></Suspense>} />
                            <Route path="/admin/CompanyListAdmin" element={<Suspense fallback="Loading ..."><CompanyListAdmin user={user} /></Suspense>} />
                            <Route path="/admin/company-update/:id" element={<Suspense fallback="Loading ..."><UpdateCompanyAdmin user={user} /></Suspense>} />
                            <Route path="/admin/company-create" element={<Suspense fallback="Loading .."><AddCompany /></Suspense>} />
                            <Route path="/admin/MessageCenterAdmin" element={<Suspense fallback="Loading ..."><MessageCenterAdmin user={user} /></Suspense>} />
                            <Route path="/admin/NewMessage" element={<Suspense fallback="Loading ..."><NewMessage /></Suspense>} />
                            <Route path="/admin/EditMessage/:id" element={<Suspense fallback="Loading ..."><EditMessage /></Suspense>} />
                            <Route path="/admin/tenant-admin/:companyId" element={<Suspense fallback="Loading ..."><TenantsAdmin user={user} /></Suspense>} />
                            <Route path="/support/messagecenter/:companyId" element={<Suspense fallback="Loading ... "><MessageCenter user={user} /></Suspense>} />
                            <Route path="/reports/diagnosticsreport" element={<Suspense fallback={"loading ..."}><ReportsDiagnostics user={user} /></Suspense>} />
                            <Route path="/admin/systemadminusers" element={<Suspense fallback="Loading ... "><SystemAdminUsersList user={user} /></Suspense>} />
                            <Route path="/admin/assign-systemadmin-users" element={<Suspense fallback="Loading ... "><SystemAdinAssignRole user={user} /></Suspense>} />
                            <Route path="/admin/HeartbeatForSearch" element={<Suspense fallback="Loading ... "><HeartbeatForSearch user={user} /></Suspense>} />
                            <Route path="/admin/NewSearchOrchestrator" element={<Suspense fallback="Loading ... "><NewSearchOrchestrator user={user} /></Suspense>} />
                            <Route path="/admin/EditSearchOrchestrator/:id" element={<Suspense fallback="Loading ... "><EditSearchOrchestrator user={user} /></Suspense>} />
                            <Route path="/admin/ViewSearchOrchestratorLogs/:id" element={<Suspense fallback="Loading ... "><ViewSearchOrchestratorLogs user={user} /></Suspense>} />
                            <Route path="/Admin/SystemEmails" element={<Suspense fallback="Loading ... "><SystemEmailsList user={user} /></Suspense>} />
                            <Route path="/Admin/SystemEmail-update/:type" element={<Suspense fallback="Loading ... "><SystemEmailForm user={user} /></Suspense>} />
                            <Route path="/Admin/SystemJobs" element={<Suspense fallback="Loading ... "><SystemJobsList user={user} /></Suspense>} />
                            <Route path="/Admin/JobOrchestrators" element={<Suspense fallback="Loading ... "><SystemOrchestratorsList user={user} /></Suspense>} />
                            <Route path="/Admin/UpdateJobOrchestrator" element={<Suspense fallback="Loading ... "><JobOrchestratorForm user={user} /></Suspense>} />
                            <Route path="/Admin/UpdateJobOrchestrator/:orchestratorId" element={<Suspense fallback="Loading ... "><JobOrchestratorForm user={user} /></Suspense>} />
                            <Route path="/Admin/system-diagnostics" element={<Suspense fallback="Loading ... "><AdminSystemDiagnosticsReport user={user} /></Suspense>} />
                            <Route path="/Admin/system-user-activity" element={<Suspense fallback="Loading ... "><AdminUserActivityReport user={user} /></Suspense>} />
                        </>
                    )}

                    {/* Catch-all (404 page not found or you are unauthorized to access). */}
                    <Route path="*" element={<NotFound location={location} />} />
                </Routes>
            </AuthenticatedTemplate >
        </PageLayout>
    );
}
export default App;